.form fieldset {
	 border: none;
	 padding: 0;
	 margin: 0;
}
 .form .form-field {
	 margin: 0 0 10px 0;
}
 .form label {
	 font-weight: 600;
	 display: block;
	 padding: 3px 0 3px 0;
}
 .form .form-input, .form .form-select, .form .form-textarea {
	 width: 100%;
	 display: inline-block;
	 box-sizing: border-box;
	 padding: 5px;
}
 .form .form-input {
	 background-color: #fff;
	 border-radius: 4px;
	 border: 1px solid #ccc;
	 color: #333;
	 border-spacing: 0;
	 border-collapse: separate;
	 height: 36px;
	 outline: none;
	 overflow: hidden;
	 position: relative;
	 width: 100%;
}
 .form .form-textarea {
	 background-color: #fff;
	 border-radius: 4px;
	 border: 1px solid #ccc;
	 color: #333;
	 border-spacing: 0;
	 border-collapse: separate;
	 outline: none;
	 position: relative;
	 width: 100%;
}
 .form .form-textarea {
	 padding: 10px;
}
 .form .buttons {
	 margin: 20px 0 0 0;
	 display: flex;
}
 .form .buttons button {
	 margin: 0 10px 0 0;
}
 .form .buttons button:last-child {
	 margin: 0;
}
 .form button {
	 padding: 10px;
	 margin: 0;
	 min-width: 100px;
}
 .form .form-field-error input, .form .form-field-error select, .form .form-field-error textarea, .form .form-field-error .Select-control {
	 border-color: red;
}
 .form .form-error {
	 display: block;
	 margin: 5px 0 0 0;
	 color: #a94443;
}
 .form-field-horizontal .line-field {
	 display: flex;
}
 .form-field-horizontal .line-field label {
	 padding: 7px 10px 0 0;
}
 .form-field-horizontal .line-field .form-input {
	 width: 100px;
}
 .form-field-checkbox label {
	 display: flex;
}
 .form-field-checkbox .form-checkbox {
	 width: 25px;
	 height: 25px;
}
 .form-field-checkbox .form-checkbox-text {
	 flex: 1;
	 align-self: center;
	 padding: 0 0 0 15px;
}
 .form-horizontal-control {
	 display: flex;
}
 .form-horizontal-control .form-field {
	 flex: 1;
	 margin-right: 10px;
}
 .form-horizontal-control .form-field:last-child {
	 margin-right: 0;
}
 .form-inline-field {
	 display: flex;
}
 .form-inline-field label {
	 padding: 8px 10px 0 0;
}
 .form-inline-field .form-select-block {
	 flex: 1;
}
 .form-inline-field-fields {
	 display: flex;
	 flex-direction: row;
}
 .form-inline-input-field {
	 display: flex;
}
 .form-inline-input-field label {
	 padding: 8px 10px 0 0;
}
 .form-inline-input-field .form-input-block {
	 flex: 1;
}
 .form-inline-select-field {
	 display: flex;
}
 .form-inline-select-field label {
	 padding: 8px 10px 0 0;
}
 .form-inline-select-field .form-select-block {
	 flex: 1;
}
 .form-inline-textarea-field {
	 display: flex;
}
 .form-inline-textarea-field label {
	 padding-right: 10px;
	 align-self: center;
}
 .form-inline-textarea-field .form-textarea-block {
	 flex: 1;
}
 .form .date-picker .form-inline-field label {
	 padding: 8px 0 0 0;
}
 .form .date-picker .form-input-block {
	 flex: 1;
	 text-align: right;
}
 .form .date-picker .form-input-block .input-wrapper input {
	 padding: 0 5px 0 5px;
	 border-radius: 4px;
	 border: 1px solid #ccc;
	 height: 34px;
	 width: 100px;
	 text-align: center;
}
 .form .field-list .fieldset-delete {
	 float: right;
	 width: 30px;
	 text-align: right;
}
 .form .field-list .form-list-block {
	 border-top: 1px solid #ccc;
	 padding: 20px 0 10px 0;
}
 .form .field-list .form-field-list-error {
	 display: block;
	 margin: 5px 0 10px 0;
	 color: #a94443;
}
 .form .form-checkboxes .form-inline-field > label {
	 padding-top: 12px;
}
 .form .day-chooser .days-chooser-days {
	 display: flex;
	 flex-direction: row;
}
 .form .day-chooser .days-chooser-days .days-chooser-day {
	 width: 30px;
}
 .form .day-chooser .days-chooser-day-parts {
	 display: flex;
	 flex-direction: row;
}
 .form .day-chooser .days-chooser-day-parts .days-chooser-day-part {
	 width: 80px;
}
 .form .vital-field .vital-dates {
	 display: flex;
	 flex-direction: row;
	 padding: 0 0 10px 0;
}
 .form .vital-field .vital-dates > div {
	 width: 100px;
	 text-align: center;
}
 .form .vital-field .vital-dates > div div:first-child {
	 font-weight: 600;
	 text-transform: capitalize;
}
 .form .vital-field .vital-dates > div:first-child {
	 width: 200px;
}
 .form .vital-field .vital-row {
	 display: flex;
	 flex-direction: row;
	 padding: 0 0 10px 0;
}
 .form .vital-field .vital-row > div:nth-child(1) {
	 width: 200px;
	 font-weight: 600;
	 padding: 5px 0 5px 0;
}
 .form .vital-field .vital-row > div:nth-child(2) {
	 width: 100px;
	 font-weight: 600;
	 padding: 5px 0 5px 0;
	 text-transform: capitalize;
}
 .form .vital-field .vital-row > div:nth-child(2) > div {
	 height: 32px;
}
 .form .vital-field .vital-row > div:nth-child(3) {
	 display: flex;
	 flex-direction: row;
}
 .form .vital-field .vital-row .vital-row-cell {
	 display: flex;
	 flex-direction: column;
	 width: 100px;
}
 .form .vital-field .vital-row .vital-row-cell .vital-value-cell {
	 position: relative;
}
 .form .vital-field .vital-row .vital-row-cell .vital-value-cell-values {
	 position: absolute;
	 top: 4px;
	 right: 2px;
	 height: 17px;
	 width: 17px;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
	 background: #eee;
	 border-radius: 50%;
	 border: 1px solid #cac3c3;
	 font-size: 12px;
	 cursor: pointer;
}
 .form .vital-field .vital-row .vital-row-cell input, .form .vital-field .vital-row .vital-row-cell .cell-value {
	 width: calc(88%);
	 height: 18px;
	 padding: 5px;
	 margin: 0 0 -1px 0;
	 border-radius: 0;
	 border-style: solid;
	 border-color: #c4c4c4;
}
 .form .vital-field .vital-row .vital-row-cell input:focus {
	 outline: none;
}
 .form .vital-field .vital-row .vital-row-cell input:disabled {
	 background-color: #b7aeae4d;
}
 .form .vital-thresholds {
	 background-color: #ddd;
	 padding: 10px;
	 border-radius: 5px;
}
 .form .vital-thresholds .thresholds-header {
	 font-weight: 600;
	 margin: 0 0 20px 0;
	 text-decoration: underline;
}
 .form .vital-thresholds label {
	 font-size: 15px;
}
 .form .threshold-field fieldset legend {
	 font-weight: 600;
	 display: block;
	 padding: 0 0 5px 0;
}
 .form .threshold-field fieldset .threshold-containers {
	 display: flex;
	 flex-direction: row;
}
 .form .threshold-field fieldset .threshold-containers .threshold-container {
	 width: 110px;
	 margin: 0 5px 0 0;
}
 .form .threshold-field fieldset .threshold-containers .threshold-container:last-child {
	 margin: 0;
}
 .form .weight-threshold-field fieldset legend {
	 font-weight: 600;
	 display: block;
	 padding: 0 0 5px 0;
}
 .form .weight-threshold-field fieldset .weight-threshold-containers {
	 display: flex;
	 flex-direction: row;
}
 .form .weight-threshold-field fieldset .weight-threshold-containers .weight-threshold-container {
	 width: 110px;
	 margin: 0 5px 0 0;
}
 .form .weight-threshold-field fieldset .weight-threshold-containers .weight-threshold-container:last-child {
	 margin: 0;
}
 .form .low-threshold-field {
	 min-width: 375px;
}
 .form .low-threshold-field fieldset legend {
	 font-weight: 600;
	 display: block;
	 padding: 0 0 5px 0;
}
 .form .low-threshold-field fieldset .low-threshold-containers {
	 display: flex;
	 flex-direction: row;
}
 .form .low-threshold-field fieldset .low-threshold-containers .low-threshold-container {
	 width: 110px;
	 margin: 0 5px 0 0;
}
 .form .low-threshold-field fieldset .low-threshold-containers .low-threshold-container:last-child {
	 margin: 0;
}
 .form .high-threshold-field {
	 min-width: 375px;
}
 .form .high-threshold-field fieldset legend {
	 font-weight: 600;
	 display: block;
	 padding: 0 0 5px 0;
}
 .form .high-threshold-field fieldset .high-threshold-containers {
	 display: flex;
	 flex-direction: row;
}
 .form .high-threshold-field fieldset .high-threshold-containers .high-threshold-container {
	 width: 110px;
	 margin: 0 5px 0 0;
}
 .form .high-threshold-field fieldset .high-threshold-containers .high-threshold-container:last-child {
	 margin: 0;
}
 .form .tooltip {
	 position: relative;
	 display: flex;
	 justify-content: center;
}
 .form .tooltip .tooltip-content {
	 position: absolute;
	 top: -52px;
	 z-index: 500;
	 color: #ffffff;
	 background: #000000;
	 padding: 10px 15px 10px 15px;
	 border-radius: 5px;
	 display: flex;
	 justify-content: center;
	 flex-direction: column;
	 align-items: center;
	 min-width: 400px;
}
 .form .tooltip .tooltip-arrow {
	 position: absolute;
	 top: -35px;
	 content: ' ';
	 border: solid #000000;
	 border-width: 13px;
	 display: inline-block;
	 transform: rotate(45deg);
}
 