button.strike-throught {
    font-size: 26px;
    cursor: pointer;
    transform: rotate(45deg);
    color: #f00;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
}