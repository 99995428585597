.call-patient-container {
    min-width: 350px;
    list-style: none;
}

.call-patient-container li a {
    color: #333;
    line-height: 30px;
}

.rpm-care-plans-filter {
    margin-bottom: 15px;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap {
    margin-bottom: 15px;
    overflow: hidden;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle {
    float: right;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle label {
    display: inline-block;
    margin-left: 15px;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle label:first-child {
    margin-left: 0;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .custom-radio > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .custom-radio > span {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .custom-radio > span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #333;
    border-radius: 50%;
    margin-right: 0.3em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .custom-radio > input:not(:disabled):not(:checked) + span:hover::before {
    border-color: #777;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .custom-radio > input:not(:disabled):active + span::before {
    background-color: #777;
    border-color: #777;
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .custom-radio > input:checked + span::before {
    border-color: #333;
    background-color: #333;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.rpm-care-plans-filter .care-plan-filter-form .rpm-care-plans-toggle-wrap .rpm-care-plans-toggle .radio {
    margin-bottom: 0.2em;
}

.rpm-care-plans-filter .care-plan-filter-form .care-plan-search-form-wrap:after {
    display: block;
    clear: both;
    content: "";
}

.rpm-care-plans-filter .care-plan-filter-form .care-plan-search-form-wrap .table-title {
    float: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 36px;
}

.rpm-care-plans-filter .care-plan-filter-form .care-plan-search-form-wrap .care-plan-search-form {
    float: right;
    width: 300px;
}

.rpm-care-plans-filter .care-plan-filter-form .care-plan-search-form-wrap .care-plan-search-form .form-field {
    margin: 0;
}

.care-plans-table .icons-wrapper {
    display: flex;
}

.care-plans-table .icons-wrapper .icon {
    display: inline-block;
    width: 40px;
    height: 30px;
    margin-right: 5px;
    background: url('../../../../static/images/icons.png');
    background-position: -41px 0;
}

.care-plans-table .icons-wrapper .icon:last-child {
    margin-right: 0;
}

.care-plans-table .icons-wrapper .icon-phone {
    background-position: 0 0;
}

.care-plans-table .icons-wrapper .icon-chat {
    background-position: -82px 0;
}

.care-plans-table .vital {
    display: flex;
}

.care-plans-table .vital .warning {
    padding: 3px 6px 0 0;
    color: #eb0707;
    font-weight: bold;
}

.care-plans-table .checkmark-status {
    border-radius: 50%;
    border: 1px solid #00af4b;
}

    .care-plans-table .checkmark-status.checked {
        background: #00af4b;
    }

.care-plans-table .checkmark-status, .care-plans-table .checkmark-vitals {
    display: inline-block;
    width: 22px;
    height: 22px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.care-plans-table .checkmark-vitals:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #00af4b;
    left: 11px;
    top: 6px;
}

.care-plans-table .checkmark-vitals:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #00af4b;
    left: 8px;
    top: 12px;
}
