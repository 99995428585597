.communications-panel {
    width: 1000px;
}

.communications-panel .communication-buttons {
    display: flex;
    margin: 10px 0;
}

.communications-panel .communication-buttons button {
    margin: 0 5px;
}

.communications-panel .communication-buttons button:first-child {
    margin-left: 0;
}

.communications-panel .communication-actions {
    width: 547px;
}
