
.rpm .timeline-container {
    flex: 0 0 100%;
    width: 100%;
    max-width: 1000px;
    display: flex;
    margin-top: 60px;
    margin-bottom: 60px;
    align-items: flex-end;
}

.rpm .timeline-container .info-99454 {
    flex: 0 0 20%;
    width: 20%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.rpm .timeline-container .info-99457-wrapper {
    flex: 0 0 80%;
    width: 80%;
}

.rpm .timeline-container .info-99457-wrapper .info-total-minutes {
    font-size: 14px;
    margin-bottom: 14px;
}

.rpm .timeline-container .info-99457-wrapper .timeline {
    height: 12px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    display: flex;
}

.rpm .timeline-container .info-99457-wrapper .timeline .timeline-item {
    position: relative;
    text-align: right;
    display: inline-block;
    width: 33.3%;
    font-size: 14px;
    margin-top: 12px;
}

.rpm .timeline-container .info-99457-wrapper .timeline-item.empty:after {
    background-position: -207px 0px;
}

.rpm .timeline-container .info-99457-wrapper .timeline-item:after {
    content: '';
    background: url('../../../../../static/images/icons.png');
    position: absolute;
    width: 16px;
    height: 28px;
    top: -46px;
    right: -8px;
    background-position: -190px 0px;
}
