.rpm .top-info-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 50px;
    z-index: 5;
}

.rpm .top-info-wrapper .patient-info-col {
    flex: 0 0 25%;
    width: 25%;
}

.rpm .top-info-wrapper .patient-info-col.fixed {
    position: sticky;
    position: -webkit-sticky;
    align-self: flex-start;
    left: 0;
    top: 0;
}
.rpm .top-info-wrapper .patient-info-col .diagnoses ul{
    padding-left:20px;
    padding-right:10px;
}
.rpm .top-info-wrapper .patient-info-col .diagnoses ul li{
    margin-bottom:5px;
}
.rpm .top-info-wrapper .patient-info-col .diagnoses ul li:last-child{
    margin-bottom:0;
}

.medications-block {
    display: flex;
    margin: 15px 0;
}

.medications-block .show-all {
    margin: 10px 0 0 0;
    cursor: pointer;
    padding: 5px 25px;
    text-align: center;
    background: #aad2ff;
}

.medications-block .medications {
    margin-left: 20px;
}

.medications-block h3 {
    margin-top: 0;
}

.medications-block .medications-list .schedule span {
    padding-left: 10px;
}

.medications-block .medications-list > li:first-child {
    margin-top: 0;
    padding-top: 0;
    border: none;
}

.medications-block .medications-list > li {
    padding-top: 9px;
    border-top: 1px solid #777;
}

.medications-block .medications-list > li ul > li:first-child {
    padding: 0;
    margin: 0;
    border: none;
}

.medications-block .medications-list > li ul > li {
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid #777;
}

.medications-block .medications-list li, .medications-block .primary-diagnoses-list li {
    margin-bottom: 3px;
}

.medications-block .primary-diagnoses-list li {
    max-width: 500px;
}

.medications-block .medications li li .part-of-day {
    text-transform: capitalize;
}

.medications-block .medications-list li li {
    list-style: none;
}

.medications-block .medications-list ul {
    display: flex;
    padding: 0 0 10px 0;
}

.medications-block .medications-list ul .checkmark {
    display: block;
    margin: 0 auto;
}

.medications-block .medications-list, .medications-block .primary-diagnoses-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.checkmark {
    display: inline-block;
    width: 16px;
    height: 16px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #00af4b;
    left: 11px;
    top: 6px;
}

.checkmark:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #00af4b;
    left: 8px;
    top: 12px;
}

.rpm .waiting-todos p {
    font-size: 14px;
    margin: 0 0 4px;
}

.rpm .waiting-todos .checkbox-row div {
    margin-left: 25px;
}