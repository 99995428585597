.care-plan-create-employee-popup .wrapper {
    padding: 15px;
    width: 700px;
}

.care-plan-create-employee-popup .form-field label  {
    font-size: 13px;
}

.care-plan-create-employee-popup .row,
.care-plan-create-employee-popup .phone-wrapper {
    display: flex;
    justify-content: space-between;
}
.care-plan-create-employee-popup .row .cell {
    width: 48%;
}

.care-plan-create-employee-popup .phone-wrapper .phone-code {
    width: 40%;
}

.care-plan-create-employee-popup .phone-wrapper .phone {
    width: 57%;
}
