.rpm-iframe .care-plan-form {
    width: 700px !important;
    margin-left: 29% !important;
}

.rpm .care-plan-form .date-picker .form-inline-field label {
    text-align: right;
    }

.rpm .care-plan-form .form-field .form-inline-select-field > label, .rpm .care-plan-form .form-field .form-inline-input-field > label, .rpm .care-plan-form .form-field .form-inline-field > label {
    width: 145px;
    }

    .rpm .care-plan-form .form-horizontal-control:nth-child(1) .form-field:nth-child(3) label {
        text-align: right;
        width: 110px;
    }

    .rpm .care-plan-form .form-horizontal-control:nth-child(1) .form-field:nth-child(4) {
        flex: 0;
    }

        .rpm .care-plan-form .form-horizontal-control:nth-child(1) .form-field:nth-child(4) label {
            text-align: right;
            padding-right: 10px;
            width: 100px;
        }

    .rpm .care-plan-form .form-horizontal-control:nth-child(1) .field-loader {
        margin: 2px 7px 0 0;
    }

    .rpm .care-plan-form .form-horizontal-control:nth-child(3) .form-field:nth-child(2) label {
        text-align: right;
    }

    .rpm .care-plan-form .field-list .day-chooser {
        padding-top: 3px;
    }

.rpm .care-plan-view > div {
    display: flex;
    flex-direction: row;
}

    .rpm .care-plan-view > div > div {
        padding: 0 0 3px 0;
        width: 170px;
    }

        .rpm .care-plan-view > div > div:first-child {
            font-weight: 600;
        }

.rpm .care-plan-comments-section {
    width: 600px;
    margin: 30px 0 0 0;
}

    .rpm .care-plan-comments-section .care-plan-comments {
        margin: 30px 0 0 0;
    }

        .rpm .care-plan-comments-section .care-plan-comments .care-plan-comment {
            padding: 7px 0 7px 0;
            border-bottom: 1px dashed #ccc;
        }

            .rpm .care-plan-comments-section .care-plan-comments .care-plan-comment .care-plan-comment-info {
                display: flex;
                flex-direction: row;
                margin: 0 0 5px 0;
            }

                .rpm .care-plan-comments-section .care-plan-comments .care-plan-comment .care-plan-comment-info div {
                    flex: 1;
                    font-weight: 600;
                }

                    .rpm .care-plan-comments-section .care-plan-comments .care-plan-comment .care-plan-comment-info div:last-child {
                        text-align: right;
                    }

                .rpm .care-plan-comments-section .care-plan-comments .care-plan-comment .care-plan-comment-body p {
                    padding: 0;
                    margin: 0;
                }

            .rpm .care-plan-comments-section .care-plan-comments .care-plan-comment:last-child {
                border-bottom: 0;
            }

.rpm .care-plan-vitals-view {
    margin: 20px 0 0 0;
    padding: 0;
    width: 400px;
}

    .rpm .care-plan-vitals-view .vital {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 10px 0 0 0;
        border-bottom: 1px dashed #ccc;
    }

        .rpm .care-plan-vitals-view .vital > div:first-child {
            font-weight: 600;
        }

        .rpm .care-plan-vitals-view .vital > div:nth-child(1) {
            width: 170px;
        }

        .rpm .care-plan-vitals-view .vital > div:nth-child(2) {
            width: 150px;
        }

        .rpm .care-plan-vitals-view .vital:last-child {
            border: 0;
        }

.rpm .care-plan-buttons {
    margin: 20px 0 0 0;
    padding: 0;
}

.rpm .daily-care-plan-view {
    display: flex;
    flex-direction: row;
    margin: 0 0 15px 0;
}

    .rpm .daily-care-plan-view .block-info > div {
        display: flex;
        flex-direction: row;
    }

        .rpm .daily-care-plan-view .block-info > div div {
            padding: 0 0 3px 0;
            width: 170px;
        }

            .rpm .daily-care-plan-view .block-info > div div:first-child {
                font-weight: 600;
            }

.rpm .daily-care-plan-week-manager {
    display: flex;
    flex-direction: row;
    margin: 20px 0 0 0;
}

    .rpm .daily-care-plan-week-manager .prev-week, .rpm .daily-care-plan-week-manager .next-week {
        padding: 10px;
        margin: 0 10px 0 0;
        border: 1px solid #ccc;
        border-radius: 2px;
        cursor: pointer;
        user-select: none;
    }

    .rpm .daily-care-plan-week-manager .disabled {
        cursor: not-allowed;
        background-color: #eee;
        color: #444;
    }

.rpm .daily-care-plan-form {
    margin: 20px 0 0 0;
    width: 1000px;
}

.rpm .daily-care-plan-entries-view {
    margin: 20px 0 0 0;
}

    .rpm .daily-care-plan-entries-view .days-of-week {
        display: flex;
        flex-direction: row;
        padding: 0 0 0 320px;
    }

        .rpm .daily-care-plan-entries-view .days-of-week .day {
            width: 100px;
            padding: 5px;
            border: 1px solid white;
        }

            .rpm .daily-care-plan-entries-view .days-of-week .day .day-name {
                font-weight: 600;
                text-transform: capitalize;
            }

    .rpm .daily-care-plan-entries-view .vital {
        display: flex;
        flex-direction: row;
    }

        .rpm .daily-care-plan-entries-view .vital .vital-name {
            font-weight: 600;
            width: 200px;
            padding: 5px;
        }

        .rpm .daily-care-plan-entries-view .vital .week-entries .part-of-day {
            display: flex;
            flex-direction: row;
        }

            .rpm .daily-care-plan-entries-view .vital .week-entries .part-of-day .part-of-day-name {
                width: 100px;
                font-weight: 600;
                text-transform: capitalize;
                padding: 5px;
            }

            .rpm .daily-care-plan-entries-view .vital .week-entries .part-of-day .entries-data {
                display: flex;
                flex-direction: row;
            }

                .rpm .daily-care-plan-entries-view .vital .week-entries .part-of-day .entries-data div {
                    padding: 5px;
                    width: 100px;
                    border: 1px solid #cccccc;
                }

.rpm .daily-care-plan-logs-loader {
    width: 1000px;
}

.rpm .daily-care-plan-logs {
    margin: 20px 0 0 0;
}

    .rpm .daily-care-plan-logs .care-plan-log {
        padding: 10px 0 10px 0;
    }

        .rpm .daily-care-plan-logs .care-plan-log > div {
            display: flex;
            flex-direction: row;
        }

            .rpm .daily-care-plan-logs .care-plan-log > div div:first-child {
                font-weight: 600;
                width: 170px;
                flex-shrink: 0;
            }

            .rpm .daily-care-plan-logs .care-plan-log > div div:last-child p {
                margin: 0;
                padding: 0;
            }

.rpm .care-plan-report-buttons {
    margin: 20px 0 0 0;
    padding: 0;
}

.rpm .care-plan-vital-popup {
    height: 80vh;
    width: 80vw;
    max-height: 300px;
    max-width: 500px;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.rpm .care-plan-vital-popup-content {
    height: 99%;
}

.rpm .care-plan-vital-popup-table {
    width: calc(80%);
    margin: 10px;
}

    .rpm .care-plan-vital-popup-table .care-plan-vital-date {
        width: 130px;
    }
