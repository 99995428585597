.dashboard .dashboard-todo table {
    width: 100%;
}

.dashboard .dashboard-todo-form {
    margin: 0 0 30px 0;
}

.dashboard .dashboard-todo-form .form {
    width: 1000px;
    margin: auto;
    display: flex;
}

.dashboard .dashboard-todo-form .form .dashboard-form-control {
    flex: 1;
}


.dashboard .dashboard-todo-form .form label[for=patient] {
    width: 88px;
}

.dashboard .dashboard-todo-form .form .buttons {
    margin: 0;
    display: flex;
    align-items: flex-end;
}

.dashboard .dashboard-todo-form .form .buttons button {
    margin: 0 0 15px 10px;
}

.dashboard .dashboard-todos thead tr th:nth-child(1) {
    width: 30px;
    text-align: center;
}

    .dashboard .dashboard-todos thead tr th:nth-child(1) span {
        display: inline-block;
        border: 1px solid #4bae53;
        border-radius: 50px;
        background-color: #fff;
        padding: 5px;
        min-width: 20px;
    }

.dashboard .dashboard-todos thead tr th:nth-child(2) {
    width: 30px;
}

.dashboard .dashboard-todos thead tr th:nth-child(3) {
    width: 150px;
}

.dashboard .dashboard-todos thead tr th:nth-child(4) {
    width: 150px;
}

.dashboard .dashboard-todos thead tr th:nth-child(5) {
    width: 150px;
}

.dashboard .dashboard-todos thead tr th:nth-child(7) {
    width: 140px;
}

.dashboard .dashboard-todos tbody tr td {
    vertical-align: middle;
}

    .dashboard .dashboard-todos tbody tr td:nth-child(1) {
        padding: 7px 1px 5px 10px;
        width: 30px;
    }

        .dashboard .dashboard-todos tbody tr td:nth-child(1) img {
            width: 20px;
            height: 20px;
        }

.dashboard .dashboard-addons table {
    width: 100%;
}

.dashboard .dashboard-addons .dashboard-addons-form .form {
    width: 1000px;
    margin: auto;
    display: flex;
}

    .dashboard .dashboard-addons .dashboard-addons-form .form .dashboard-form-control {
        flex: 1;
    }

    .dashboard .dashboard-addons .dashboard-addons-form .form label[for=doctor] {
        width: 83px;
    }

    .dashboard .dashboard-addons .dashboard-addons-form .form label[for=patient] {
        width: 83px;
    }

    .dashboard .dashboard-addons .dashboard-addons-form .form .buttons {
        margin: 0;
        display: flex;
        align-items: flex-end;
    }

        .dashboard .dashboard-addons .dashboard-addons-form .form .buttons button {
            margin: 0 0 15px 10px;
        }

    .dashboard .dashboard-addons .dashboard-addons-form .form .form-doctor-control .form-field:nth-child(1) {
        flex: none;
        width: 335px;
    }

    .dashboard .dashboard-addons .dashboard-addons-form .form .form-doctor-control .form-field:nth-child(2) {
        flex: none;
        width: 235px;
    }

    .dashboard .dashboard-addons .dashboard-addons-form .form .form-patient-control .form-field:nth-child(1) {
        flex: none;
        width: 335px;
    }

    .dashboard .dashboard-addons .dashboard-addons-form .form .form-patient-control .form-field:nth-child(3) {
        flex: none;
        width: 200px;
    }

.dashboard .dashboard-addons thead tr th:nth-child(1) {
    width: 30px;
}

.dashboard .dashboard-addons thead tr th:nth-child(2) {
    width: 70px;
}

.dashboard .dashboard-addons thead tr th:nth-child(3) {
    width: 150px;
}

.dashboard .dashboard-addons thead tr th:nth-child(4) {
    width: 150px;
}

.dashboard .dashboard-addons thead tr th:nth-child(6) {
    width: 60px;
}

.dashboard .dashboard-addons thead tr th:nth-child(7) {
    width: 60px;
}

.dashboard .dashboard-addons tbody tr td {
    vertical-align: middle;
}

    .dashboard .dashboard-addons tbody tr td:nth-child(2) {
        white-space: nowrap;
    }

    .dashboard .dashboard-addons tbody tr td:nth-child(3) {
        white-space: nowrap;
    }

.dashboard .dashboard-table header {
    font-weight: 600;
}

.dashboard .dashboard-table:last-child {
    margin: 30px 0 0 0;
}

.dashboard .search table {
    width: 100%;
}
